import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Input } from '@components/Input';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Input} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Input />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "placeholder"
    }}>{`Placeholder`}</h2>
    <Playground __position={2} __code={'<Input placeholder=\"Placeholder\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input placeholder="Placeholder" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "icon"
    }}>{`Icon`}</h2>
    <Playground __position={3} __code={'<Input icon=\"envelope\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input icon="envelope" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <Playground __position={4} __code={'<Input icon=\"envelope\" placeholder=\"Email address\" onClear={() => {}} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Input icon="envelope" placeholder="Email address" onClear={() => {}} mdxType="Input" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      